import { ReactElement } from "react";
import AboutContentList, { IAboutContentListItem } from "./AboutContentList";
import AboutContentDeleteCache from "./AboutContentDeleteCache";

/**
 *  About content
 */

const CASHE_INFO: IAboutContentListItem[] = [
    { boldText: "Lista de monedas", text: " - 30 dias." },
    { boldText: "Resultados y operaciones", text: " - 1 hora." },

]

const TABS_INFO: IAboutContentListItem[] = [
    { boldText: "Converter", text: " - currency conversion, which can be used to convert any amount from one currency to another." },
    { boldText: "Fluctuations", text: " - fluctuation currencies. Hou will be able to retrieve information about how currencies fluctuate on a day-to-day basis.  Please note that the maximum allowed timeframe is 365 days." },
    { boldText: "Latest Exchange Rates", text: " - real-time exchange rate." },
    { boldText: "Exchange Rate History", text: " - historical rates are available for most currencies all the way back to the year of 1999." },
]


const AboutContent = (): ReactElement => (
    <>
        <div className="mb-4 text-uppercase">
            <h3>Detalles</h3>
        </div>

        <AboutContentList
            title="Los datos son guardado en la cache con IndexedDB"
            list={CASHE_INFO}
        />

        <AboutContentDeleteCache />
    </>
);


export default AboutContent;
