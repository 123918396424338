import { Table } from "react-bootstrap";
import { IApiLatestExchangeRates, IApiAllAvailableCurrencies } from "../../../api/exchange-rates-service.types";
import { ReactElement, memo } from "react";

/**
 *  Latest exchange rates result
 */

interface ILatestExchangeRatesResult {
    result: IApiLatestExchangeRates;
    availableCurrencies: IApiAllAvailableCurrencies | undefined;
}

interface IRow {
    currCode: string;
    curr?: string;
    rate: number;
}


const TableHeader = (): ReactElement =>
    <thead>
        <tr>
            <th>Currency Code</th>
            <th>Precio</th>
            <th>Moneda</th>
        </tr>
    </thead>


const Row = memo(({ currCode, curr, rate }: IRow): ReactElement =>
    <tr key={currCode}>
        <td>{currCode}</td>
        <td>{rate}</td>
        <td>{curr}</td>
    </tr>,
    (prevProps, nextProps) => {
        return prevProps.rate === nextProps.rate;
    }
);

const LatestExchangeRatesResult = ({
    result,
    availableCurrencies
}: ILatestExchangeRatesResult): ReactElement => {

    const allowedCurrencies = [
        "ARS", "CLP", "COP", "MXN", "BRL", "PEN", "HNL", "NIO", "PAB", "BOB", 
        "GYD", "EUR", "PYG", "UYU", "CRC", "GTQ", "USD"
    ];

    return (
        <>
            <h2>Moneda: {result.base}</h2>

            <Table striped bordered hover>
                <TableHeader />

                <tbody>
                    {Object.keys(result.rates)
                        .filter(currCode => allowedCurrencies.includes(currCode)) 
                        .map(currCode => (
                            <Row
                                key={currCode}
                                currCode={currCode}
                                curr={availableCurrencies?.symbols[currCode]}
                                rate={result.rates[currCode]}
                            />
                        ))
                    }
                </tbody>
            </Table>
        </>
    );
};


export default LatestExchangeRatesResult;